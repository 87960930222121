import React from "react";
import { Context } from "Store";
import GridItem from "components/Grid/GridItem.js";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import car from "../assets/img/stak-bike.png";
import sudan1 from "../assets/img/sedan_parking1.png";
import electric1 from "../assets/img/electric1.png";
import sudan2 from "../assets/img/sedan_parking2.png";
import suv1 from "../assets/img/suv_parking1.webp";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CardHeader from "@material-ui/core/CardHeader";
import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
const useStyles = makeStyles({
  ...styles,
  title: {
    fontSize: 14,
  },
  badge: {
    top: 20,
    right: 20,
  },
});

function PlanCard() {
  const classes = useStyles();
  const planImages = new Map();

  const { state } = React.useContext(Context);
  const { plan } = state;

  planImages.set("Electric Car", electric1);
  planImages.set("sedan", sudan1);
  planImages.set("Electric SUV", suv1);
  planImages.set("Non Electric SUV", sudan2);
  planImages.set("Non Electric SUV", sudan2);

  const getBadge = (plan) => {
    if (plan.offer) {
      return (
        <span>
          <LocalOfferIcon fontSize="small" /> Offer{" "}
        </span>
      );
    }
    return 0;
  };

  return (
    <GridContainer justify="center" style={{ margin: 10 }}>
      <Grid item xs={12} sm={6} md={6} style={{ margin: 5 }}>
        <Badge
          classes={{ anchorOriginTopRightRectangle: classes.badge }}
          color={plan.offer ? "secondary" : "primary"}
          badgeContent={getBadge(plan)}
        >
          <Card
            style={{
              backgroundColor: plan.offer === 1 ? "#ead4a3" : "white",
              minHeight: "30vh",
            }}
          >
            <img
              src={
                planImages.get(plan.vehicle_type)
                  ? planImages.get(plan.vehicle_type)
                  : car
              }
              style={{
                minHeight: "170px",
              }}
              alt="Home header"
              width="70%"
            />
            <CardHeader
              title={plan.plan_name}
              style={{ paddingBottom: "0px", paddingTop: "0px" }}
            />

            <CardContent style={{ paddingBottom: "0px", paddingTop: "0px" }}>
              <Box fontWeight="fontWeightBold" m={1} align="left">
                {`${plan.duration} Month(s)`}
              </Box>
              <Box fontWeight="fontWeightLight" m={1} align="left">
                {`${plan.notes ? plan.notes : ""}`}
              </Box>
              <Box fontWeight="fontWeightBold" m={1} align="left">
                {`$${plan.amount / plan.duration}/month`}
              </Box>
            </CardContent>
          </Card>
        </Badge>
      </Grid>
    </GridContainer>
  );
}

export default PlanCard;
