import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography, Button, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import history from "utils/history";
import CardService from "../../services/CardService";
import useAPI from "useAPI";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
// import AddIcon from "@material-ui/icons/Add";
// import Autocomplete from "@material-ui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
    color: "#324152",
    marginTop: 20,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
  fab: {
    position: "absolute",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function CardList() {
  const classes = useStyles();

  const api = useAPI();
  const [cards, setCards] = React.useState([]);

  const fetchCards = async () => {
    const resposne = await CardService.getCards();
    setCards(resposne);
  };

  React.useEffect(() => {
    CardService.init(api);
    fetchCards();
  }, []);

  const addCard = () => {
    history.push("/payment-card/new");
  };
  const deleteCard = (card) => {
    if (!card.is_default) {
      CardService.deleteCard(card);
      const intervalRef = setTimeout(function () {
        fetchCards();
      }, 1000);
      return () => {
        clearTimeout(intervalRef);
      };
    }
  };
  const makeDefault = (card) => {
    if (!card.is_default) {
      CardService.setDefaultCard(card);
      const intervalRef = setTimeout(function () {
        fetchCards();
      }, 500);
      return () => {
        clearTimeout(intervalRef);
      };
    }
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          Select Default Card
        </Typography>
      </GridItem>
      {/* <GridItem align="right">
        <Button color="primary" variant="contained" onClick={() => addCard()}>
          Add Card
        </Button>
      </GridItem> */}
      <GridItem xs={12} sm={12} md={8} align="center">
        <div className={classes.root}>
          <List>
            {cards &&
              cards.map((card) => (
                <ListItem
                  divider
                  key={card.id}
                  button
                  value={card}
                  onClick={() => makeDefault(card)}
                  style={{
                    backgroundColor: card.is_default ? green[500] : "#e0e3e7",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <ListItemIcon>
                    <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`**** **** **** ${card.number.slice(
                      card.number.length - 5
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    {card.is_default ? (
                      <IconButton>
                        {" "}
                        <CheckCircleIcon style={{ color: "#006600" }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => deleteCard(card)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </GridItem>
      {/* <Fab
        aria-label="Add"
        className={classes.fab}
        color="primary"
        onClick={() => addCard()}
      >
        <AddIcon />
      </Fab> */}
      <Button
        onClick={() => addCard()}
        variant="contained"
        color="primary"
        className={classes.fab}
      >
        Add Payment Option
      </Button>
    </AuthenticatedContainer>
  );
}

export default CardList;
