import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getTicketByToken = async (token) => {
  try {
    let cancel;
    const response = await api.get("/ticket/" + token, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getTicketReceiptByToken = async (token) => {
  try {
    let cancel;
    const response = await api.get(`/ticket/${token}/transactions`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const getTicketList = async () => {
  try {
    let cancel;
    const response = await api.get("/tickets", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const TicketService = {
  init,
  getTicketByToken,
  getTicketReceiptByToken,
  getTicketList,
};

export default TicketService;
