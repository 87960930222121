import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Button, TextField, Typography } from "@material-ui/core";
import history from "utils/history";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import CardService from "../../services/CardService";
import useAPI from "useAPI";
import InputMask from "react-input-mask";
import Loading from "components/Loading";
import { Context } from "Store";
import types from "Reducer/types";

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
    color: "#324152",
    marginTop: 40,
    padding: 25,
    borderRadius: 15,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
}));
function CardForm({ id = null, isReadable = null }) {
  const classes = useStyles();
  const api = useAPI();
  const [isLoading, setIsLoading] = React.useState(true);
  const { state, dispatch } = React.useContext(Context);
  const {
    parked,
    hasSubscriptionBooking,
    subscribed,
    paymentMethod,
    plan,
  } = state;
  const [card, setCard] = React.useState({
    id: null,
    cvc: "",
    expiry: "",
    focused: "",
    name: "",
    number: "",
  });

  const [focus, setFocus] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCard({ ...card, [name]: value });
  };

  const addCard = async () => {
    setErrorMessage("");
    CardService.storeCard(card)
      .then((res) => {
        dispatch({
          type: types.SET_PAYMENT_METHOD,
          payload: true,
        });
        if (!subscribed && plan) {
          setTimeout(() => {
            // redirect on confirmation screen
            history.push({
              pathname: "/confirm-reserve",
              // state: { isRedirectFromCard: true },
            });
          }, 1000);
        } else {
          setTimeout(() => {
            history.push("/payment-cards");
          }, 1000);
        }
      })
      .catch((e) => {
        console.log(e.response.data.error);
        setErrorMessage(e.response.data.error);
      });
  };
  React.useEffect(() => {
    CardService.init(api);
    if (id) fetchUserCard();
  }, [api]);

  const fetchUserCard = async () => {
    await CardService.getCardById(id).then((res) => {
      if (res) {
        console.log(res);
        res.expiry = res.valid_month + "/" + res.valid_year;
        setCard(res);
        setIsLoading(false);
      }
    });
  };

  if (id && isLoading) {
    return <Loading />;
  }

  return (
    <>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Cards
          cvc={card.cvc}
          expiry={card.expiry}
          focused={focus}
          locale={{ valid: "Valid till" }}
          name={card.name}
          number={card.number}
        />
      </GridItem>
      <div className={classes.root}>
        <GridItem xs={12} sm={12} md={12} align="center">
          <InputMask
            mask="9999 9999 9999 9999"
            value={card.number}
            maskChar=" "
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            disabled={isReadable ? isReadable : false}
          >
            {() => (
              <TextField
                margin="dense"
                id="card-numer"
                name="number"
                label="Card Number"
                variant="outlined"
                type="text"
                fullWidth
              />
            )}
          </InputMask>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} align="center">
          <TextField
            margin="dense"
            id="card-name"
            value={card.name}
            name="name"
            label="Card Holder Name"
            // value={values.city}
            variant="outlined"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            type="text"
            fullWidth
            disabled={isReadable ? isReadable : false}
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          align="center"
          style={{ display: "flex" }}
          p={2}
          m={5}
        >
          <InputMask
            mask="99/99"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            maskChar=" "
            value={card.expiry}
            disabled={isReadable ? isReadable : false}
          >
            {() => (
              <TextField
                margin="dense"
                id="card-expiry"
                name="expiry"
                label="Valid Till"
                variant="outlined"
                type="text"
                fullWidth
              />
            )}
          </InputMask>
          <InputMask
            mask="999"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            maskChar=" "
            value={card.cvc}
            disabled={isReadable ? isReadable : false}
          >
            {() => (
              <TextField
                id="card-cvc"
                margin="dense"
                name="cvc"
                label="CVC"
                variant="outlined"
                type="text"
                fullWidth
              />
            )}
          </InputMask>
        </GridItem>
        <GridItem align="center" style={{ padding: 10 }}>
          {(errorMessage && (
            <Typography
              variant="caption"
              align="center"
              style={{
                color: "red",
              }}
            >
              {errorMessage}
            </Typography>
          )) ||
            null}
        </GridItem>

        {!isReadable && (
          <GridItem align="right" style={{ padding: 10 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => addCard()}
              fullWidth
            >
              {(id && "Update Card") || "Add Card"}
            </Button>
          </GridItem>
        )}
      </div>
    </>
  );
}

CardForm.propTypes = {
  id: PropTypes.string,
};

CardForm.defaultProps = {
  id: null,
};

export default CardForm;
