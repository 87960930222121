import React from "react";
import { Context } from "Store";
import types from "Reducer/types";
// core components
import GridItem from "components/Grid/GridItem.js";
import SubscriptionService from "../services/SubscriptionService";
import CardService from "../services/CardService";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import car from "../assets/img/stak-bike.png";
// import sudan1 from "../assets/img/sedan_parking1.png";
// import electric1 from "../assets/img/electric1.png";
// import sudan2 from "../assets/img/sedan_parking2.png";
// import suv1 from "../assets/img/suv_parking1.webp";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CardHeader from "@material-ui/core/CardHeader";
import useAPI from "useAPI";
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  Paper,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
// import moment from "moment";
import history from "utils/history";
import Snackbar from "@material-ui/core/Snackbar";
// import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";
// import HomeIcon from "@material-ui/icons/Home";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import CreditCardIcon from "@material-ui/icons/CreditCard";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  ...styles,
  title: {
    fontSize: 14,
  },
  badge: {
    top: 20,
    right: 20,
  },
});

function ConfirmPlan() {
  const classes = useStyles();
  const api = useAPI();
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const planImages = new Map();
  const [card, setCard] = React.useState(null);
  const [code, setCode] = React.useState("");
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [codeError, setCodeError] = React.useState("");

  const { state, dispatch } = React.useContext(Context);
  const { hasSubscriptionBooking, paymentMethod, plan } = state;
  const [discountModel, setDiscountModel] = React.useState(false);

  // planImages.set("Electric Car", electric1);
  // planImages.set("sedan", sudan1);
  // planImages.set("Electric SUV", suv1);
  // planImages.set("Non Electric SUV", sudan2);
  // planImages.set("Non Electric SUV", sudan2);

  const handleReserve = async () => {
    setOpen(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getDiscountButtonColor = () => {
    if (discountAmount !== 0) {
      return green[500];
    }
  };

  const handlePromoCode = async () => {
    if (code === "") {
      setCodeError("error");
      return;
    }
    setDiscountAmount(10);
    setDiscountModel(false);
    // await api
    // .post("/apply-user-discount", {
    //   session: state.parkingSession,
    //   code,
    // })
    // .then((res) => {
    //   console.log("RES", res);
    //   if (res.data.err) {
    //     setcouponErrorMsg(res.data.message);
    //     setcouponErrorOpen(true);
    //   } else {
    //     setDiscountModel(false);
    //     let discount_amount = res.data.discountAmount;
    //     if (res.data.discountAmount >= res.data.totalAmount)
    //       discount_amount = res.data.totalAmount;
    //     let charged_amount = res.data.chargedAmount;
    //     let discountAmount = {
    //       total_amount: res.data.totalAmount,
    //       discount_amount,
    //       charged_amount,
    //     };
    //     dispatch({
    //       type: types.SET_DISCOUNT_AMOUNT,
    //       payload: discountAmount,
    //     });
    //   }
    // });
  };

  const handleConfirm = async () => {
    setSaving(true);
    await SubscriptionService.createPlan(plan.id).then((res) => {
      setOpenSnackbar(true);
      console.log("RESPONSE", res);
      if (hasSubscriptionBooking) {
        dispatch({
          type: types.SET_SUBSCRIBED,
          payload: true,
        });
        history.push({
          pathname: "/reserve",
          state: { isRedirectFromPlan: true },
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
    });
    setOpen(false);
    setSaving(false);
  };

  const fetchUserDefaultCard = async () => {
    await CardService.getUserDefaultCard().then((res) => {
      console.log("card", res);
      setCard(res);
    });
  };

  const fetchCurrentBooking = async () => {
    await SubscriptionService.getSubscriptionBooking().then((res) => {
      console.log("rrrrresss", res);
      if (res) {
        if (res.payment_status === "paid") {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: true,
          });
        } else {
          dispatch({
            type: types.SET_SUBSCRIBED,
            payload: false,
          });
        }
        console.log("paymentcard", paymentMethod);
        // if (!paymentMethod) {
        //   history.push({ pathname: "/payment-card/new" });
        // }
      } else {
        history.push({
          pathname: "/vehicles/new",
          state: { isRedirectFromHome: true },
        });
      }
    });
  };

  React.useEffect(() => {
    SubscriptionService.init(api);
    CardService.init(api);

    fetchCurrentBooking();
    fetchUserDefaultCard();
  }, [api]);

  const getBadge = (plan) => {
    if (plan.offer) {
      return (
        <span>
          <LocalOfferIcon fontSize="small" /> Offer{" "}
        </span>
      );
    }
    return 0;
  };

  return (
    <GridContainer justify="center" style={{ margin: 10 }}>
      <GridItem xs={12} sm={12} md={12} align="center">
        <Dialog
          open={discountModel}
          onClose={() => {
            setDiscountModel(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Apply Coupon Code "}
          </DialogTitle>
          <DialogContent>
            <TextField
              error={codeError === "error"}
              autoFocus
              margin="dense"
              label="Code"
              value={code}
              variant="outlined"
              onChange={(e) => {
                setCode(e.target.value);
              }}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDiscountModel(false);
                setDiscountAmount(0);
                setCode("");
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handlePromoCode} color="primary" autoFocus>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} align="center">
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure to want to subscribe ${
                plan ? plan.plan_name : ""
              } plan?`}
            </DialogContentText>
            {(!process.env.REACT_APP_HIDE_SUBSCRIPTION_PRICE && (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Subtotal</TableCell>
                      <TableCell align="right">{`$${plan.amount}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Discount</TableCell>
                      <TableCell align="right">{`$${discountAmount}`}</TableCell>
                    </TableRow>
                    <TableRow selected={true}>
                      <TableCell>Total</TableCell>
                      <TableCell align="right">{`${
                        plan.amount - discountAmount
                      }`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )) ||
              null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleConfirm();
              }}
              color="primary"
              disabled={saving}
              autoFocus
            >
              Confirm
            </Button>
            {saving && (
              <CircularProgress size={14} className={classes.buttonProgress} />
            )}
          </DialogActions>
        </Dialog>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          align="center"
          style={{ paddingBottom: 15 }}
        >
          <Typography variant="h5" color="primary">
            {"CONFIRM SUBSCRIPTION"}
          </Typography>
        </GridItem>
        {card && (
          <Grid item xs={12} sm={6} md={3} style={{ margin: 10 }}>
            {"Payment will be done by:"}
            <List>
              <ListItem
                divider
                key={card.id}
                button
                value={card}
                style={{
                  backgroundColor: card.is_default ? green[500] : "#e0e3e7",
                  borderRadius: 5,
                  marginBottom: 10,
                }}
              >
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`**** **** **** ${card.number.slice(
                    card.number.length - 5
                  )}`}
                />
                <ListItemSecondaryAction>
                  <IconButton>
                    {" "}
                    <CheckCircleIcon style={{ color: "#006600" }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3} style={{ margin: 5 }}>
          <Badge
            classes={{ anchorOriginTopRightRectangle: classes.badge }}
            color={plan.offer ? "secondary" : "primary"}
            badgeContent={getBadge(plan)}
          >
            <Card
              style={{
                backgroundColor: plan.offer === 1 ? "#ead4a3" : "white",
                minHeight: "40vh",
              }}
            >
              <img
                src={
                  planImages.get(plan.vehicle_type)
                    ? planImages.get(plan.vehicle_type)
                    : car
                }
                style={{
                  minHeight: "110px",
                  marginTop: 15,
                  marginBottom: 15,
                }}
                alt="Home header"
                width="70%"
              />
              <CardHeader
                title={plan.plan_name}
                style={{ paddingBottom: "0px", paddingTop: "0px" }}
              />

              <CardContent style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                <Box fontWeight="fontWeightBold" m={1} align="left">
                  {`${plan.duration} Month(s)`}
                </Box>
                <Box fontWeight="fontWeightLight" m={1} align="left">
                  {`${plan.notes ? plan.notes : ""}`}
                </Box>
                {(!process.env.REACT_APP_HIDE_SUBSCRIPTION_PRICE && (
                  <Box fontWeight="fontWeightBold" m={1} align="left">
                    {`$${plan.amount / plan.duration}/month`}
                  </Box>
                )) ||
                  null}

                {/* <Button
                  variant="contained"
                  // disabled={!!Object.keys(discountamount).length}
                  fullWidth
                  style={{
                    // color: activeColor,
                    marginTop: 10,
                    marginBottom: 15,
                    backgroundColor: getDiscountButtonColor(),
                  }}
                  onClick={() => {
                    setDiscountModel(true);
                  }}
                >
                  {discountAmount !== 0
                    ? `Promo Code Applied Successfully`
                    : "Do you have Promo Code?"}
                </Button> */}
              </CardContent>
            </Card>
          </Badge>
        </Grid>
        <Grid item xs={12} sm={6} md={3} style={{ margin: 10 }}>
          <Typography variant="h5" gutterBottom>
            <Button
              onClick={() => handleReserve(plan)}
              disabled={false}
              color="primary"
              variant="contained"
              fullWidth
              style={{
                marginTop: plan.notes ? 20 : 20,
                color: "white",
              }}
            >
              Confirm Now
            </Button>
          </Typography>
        </Grid>
        <div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert severity="success" onClose={handleCloseSnackbar}>
              Plan Subscribed Successfully
            </Alert>
          </Snackbar>
        </div>
      </GridItem>
    </GridContainer>
  );
}

export default ConfirmPlan;
