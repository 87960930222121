import React from "react";

import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import PaymentService from "../../services/PaymentService";
import useAPI from "useAPI";
import { green, red } from "@material-ui/core/colors";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { Button } from "@material-ui/core";

import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// import Autocomplete from "@material-ui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    color: "#324152",
    marginTop: 20,
    marginBottom: 80,
  },
  tabStyle: { color: "#324152", marginTop: 20 },
  fab: {
    position: "absolute",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function SubscriptionTransactions() {
  const classes = useStyles();

  const api = useAPI();
  const [transactions, setTransactions] = React.useState([]);
  const [transaction, setTransaction] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const fetchTransactions = async () => {
    const resposne = await PaymentService.getSubscriptionTransactions();
    setTransactions(resposne);
  };
  const getDate = (dateTime) => {
    // return new Date(dateTime);
    return moment(dateTime).format("L");
  };
  React.useEffect(() => {
    PaymentService.init(api);
    fetchTransactions();
  }, []);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    setTransaction(transaction);
    setOpenConfirmation(true);
    // PaymentService.getSubscriptionInvoiceLink(transaction).then((result) => {
    //   openInNewTab(result);
    // });
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          Payment History
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align="center">
        <div className={classes.root}>
          <List>
            {transactions &&
              transactions.map((transaction) => (
                <ListItem
                  divider
                  key={transaction.id}
                  button
                  value={transaction}
                  style={{
                    backgroundColor:
                      transaction.payment_status === "paid"
                        ? green[100]
                        : red[100],
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <ListItemText
                    primary={` Amount: $${transaction?.plan?.amount} for ${
                      transaction?.plan?.plan_name || ""
                    }`}
                    primaryTypographyProps={{
                      style: { color: "#111", fontSize: 14 },
                    }}
                    secondary={
                      <React.Fragment>
                        Start Date: {getDate(transaction.start_date)}
                        <br />
                        {/* Next Billing Date: {getDate(transaction.next_billing_date)} 
                        <br />*/}
                        Vehicle: {transaction.vehicle_name}
                        <br />
                        Status:{" "}
                        {String(transaction.payment_status).toUpperCase()}
                        <br />
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => viewInvoice(transaction)}>
                      <ReceiptIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Dialog
            open={openConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Transaction Details"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                If you have any questions regarding this transaction, please
                contact the administrator with the following details:
                <br />
                Subscription ID: {transaction?.authorizenet_subscription_id}
                <br />
                Amount: ${transaction?.plan?.amount}
                <br />
                Plan: {transaction?.plan?.plan_name}
                <br />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenConfirmation(false);
                }}
                color="primary"
                variant="outlined"
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default SubscriptionTransactions;
