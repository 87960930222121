/* eslint-disable react/display-name */

import React from "react";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Row, Col } from "reactstrap";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import useAPI from "useAPI";
import UserService from "../services/UserService";
import SubscriptionService from "../services/SubscriptionService";
import Snackbar from "@material-ui/core/Snackbar";
// import Alert from "@material-ui/lab/Alert";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(() => ({
  ...styles,
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export const NotifyAttendant = () => {
  const classes = useStyles();
  const titleList = [
    { id: 1, name: "Request for PARK" },
    { id: 2, name: "Request for RETRIEVE" },
    { id: 3, name: "Request for Repair" },
  ];

  const [saving, setSaving] = React.useState(false);
  const api = useAPI();
  const [values, setValues] = React.useState({});
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    UserService.init(api);
    SubscriptionService.init(api);
  }, [api]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSend = async () => {
    console.log(values);
    setSaving(true);
    await SubscriptionService.notifyAttendant(values).then((res) => {
      setOpen(true);
      console.log("notify adminb", res);
    });
    setSaving(false);
  };

  return (
    <GridContainer justify="center" className={"m-5"}>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Row className="mb-5 text-center text-md-center lead text-muted">
          <Col>
            <b>{"NOTIFY ATTENDANT"}</b>
          </Col>
        </Row>
        <Row className="mb-2">
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}
          >
            <InputLabel id="state-id">Title</InputLabel>
            <Select
              label="Title"
              value={values.title}
              required
              onChange={handleChange("title")}
            >
              <MenuItem
                disabled
                value=""
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Title
              </MenuItem>

              {titleList &&
                titleList.map((item) => (
                  <MenuItem
                    value={`${item.name}`}
                    key={`${item.id}`}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {`${item.name}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Row>{" "}
        <Row>
          <TextField
            id="outlined-multiline-static"
            label="Message"
            multiline
            rows={4}
            defaultValue=""
            variant="outlined"
            fullWidth
            onChange={handleChange("message")}
          />
        </Row>{" "}
        <Row>
          {/* <div className={classes.wrapper}> */}
          <Button
            onClick={handleSend}
            variant="contained"
            disabled={saving}
            color="primary"
            fullWidth
            className="mt-3"
          >
            Send
          </Button>
          {saving && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Row>
        <div>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            // TransitionComponent={transition}
            // key={transition ? transition.name : ""}
          >
            <Alert severity="success" onClose={handleClose}>
              Attendant Notified Successfully
            </Alert>
          </Snackbar>
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default NotifyAttendant;
